<template>
  <div class="forgot-password-sheet">
    <div style="margin-top: 12px">
      <a
        @click="
          visible = true;
          showCaptcha();
        "
        class="ma-0 text-caption"
        >Forgot Password?</a
      >
      <p class="ma-0 text-caption">
        Have trouble logging in?
        <a @click="showSupportSheet = true">Contact Support</a>
      </p>
    </div>
    <v-bottom-sheet
      scrollable
      persistent
      :width="$vuetify.breakpoint.mdAndDown ? '100%' : '60%'"
      :value="showSupportSheet"
      @click:outside="showSupportSheet = false"
    >
      <v-card>
        <v-card-title class="pb-2">
          Contact Support<v-spacer></v-spacer>

          <v-btn icon @click="showSupportSheet = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <div class="mb-4">
          <!-- <v-list-item href="tel:+917900087696">
            <v-list-item-avatar><v-icon>mdi-phone</v-icon></v-list-item-avatar>
            <v-list-item-title>+91 79000 87696</v-list-item-title>
            <v-list-item-action
              ><v-icon>mdi-arrow-right</v-icon></v-list-item-action
            >
          </v-list-item>
          <v-list-item href="tel:+919082573219">
            <v-list-item-avatar><v-icon>mdi-phone</v-icon></v-list-item-avatar>
            <v-list-item-title>+91 90825 73219</v-list-item-title>
            <v-list-item-action
              ><v-icon>mdi-arrow-right</v-icon></v-list-item-action
            >
          </v-list-item> -->
          <!-- <v-list-item href="tel:+918149640459">
            <v-list-item-avatar><v-icon>mdi-phone</v-icon></v-list-item-avatar>
            <v-list-item-title>+91 81496 40459</v-list-item-title>
            <v-list-item-action
              ><v-icon>mdi-arrow-right</v-icon></v-list-item-action
            >
          </v-list-item> -->
          <v-list-item href="tel:+919175915234">
            <v-list-item-avatar><v-icon>mdi-phone</v-icon></v-list-item-avatar>
            <v-list-item-title>+91 9175915234</v-list-item-title>
            <v-list-item-action
              ><v-icon>mdi-arrow-right</v-icon></v-list-item-action
            >
          </v-list-item>
        </div>
      </v-card>
    </v-bottom-sheet>
    <v-bottom-sheet
      scrollable
      persistent
      :width="$vuetify.breakpoint.mdAndDown ? '100%' : '60%'"
      :value="visible"
      @click:outside="closeDialog"
    >
      <v-card>
        <v-card-title class="pb-2">
          Forgot Password?<v-spacer></v-spacer>

          <v-btn icon @click="closeDialog"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-subtitle class="pb-2 pt-1"
          ><span v-if="!usernameVerified"
            >Please fill in your username to receive an OTP on your registered
            phone number</span
          ><span v-else
            >Please enter the 6 digit OTP you received in your SMS</span
          ></v-card-subtitle
        >
        <div class="mb-4">
          <div class="text-center" v-if="!otpVerified">
            <div id="recaptcha-container"></div>
          </div>
          <div v-if="captchaVerified && !usernameVerified">
            <v-form
              v-model="usernameForm.valid"
              class="mx-6"
              @submit.prevent="sendOTP"
            >
              <!-- text field -->
              <v-text-field
                required
                hint="In most of the cases this is your phone number. If you do not remember your username, please contact your institution!"
                persistent-hint
                outlined
                @input="usernameForm.error = null"
                :rules="[(v) => !!v || 'Required']"
                hide-details="auto"
                :error-messages="usernameForm.error"
                v-model="usernameForm.username"
              >
                <template #label>
                  Username<span class="red--text"><strong> * </strong></span>
                </template>
              </v-text-field>
              <v-btn
                type="submit"
                :disabled="!usernameForm.valid || loading"
                block
                color="primary"
                >Get OTP</v-btn
              >
            </v-form>
          </div>
          <div v-else-if="usernameVerified && !otpVerified">
            <v-form
              v-model="codeForm.valid"
              class="mx-6"
              @submit.prevent="verifyCode"
            >
              <!-- text field -->
              <v-text-field
                required
                persistent-hint
                outlined
                @input="codeForm.error = null"
                :rules="[(v) => !!v || 'Required']"
                hide-details="auto"
                :error-messages="codeForm.error"
                v-model="codeForm.code"
              >
                <template #label>
                  6-Digit OTP<span class="red--text"><strong> * </strong></span>
                </template>
              </v-text-field>
              <v-row justify="center" no-gutters class="mt-3">
                <v-col class="mr-1">
                  <v-btn
                    :disabled="loading || !enableResendButton"
                    color="primary"
                    @click="sendOTP"
                    block
                    >Resend OTP</v-btn
                  >
                </v-col>
                <v-col class="ml-1">
                  <v-btn
                    type="submit"
                    block
                    :disabled="!codeForm.valid || loading"
                    color="primary"
                    >Submit</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </div>
          <div v-else-if="otpVerified">
            <v-form
              v-model="usernameForm.valid"
              class="mx-6"
              @submit.prevent="resetPassword"
            >
              <!-- text field -->
              <v-text-field
                class="mb-2"
                required
                persistent-hint
                outlined
                @input="newPasswordForm.passwordError = null"
                :rules="newPasswordForm.newPasswordRules"
                hide-details="auto"
                :append-icon="newPasswordForm.showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="newPasswordForm.showNewPassword ? 'text' : 'password'"
                @click:append="newPasswordForm.showNewPassword = !newPasswordForm.showNewPassword"
                :error-messages="newPasswordForm.passwordError"
                v-model="newPasswordForm.newPassword"
              >
                <template #label>
                  New Password<span class="red--text"
                    ><strong> * </strong></span
                  >
                </template>
              </v-text-field>
              <v-text-field
                required
                class="mb-2"
                persistent-hint
                outlined
                :append-icon="newPasswordForm.showRetypePassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="newPasswordForm.showRetypePassword ? 'text' : 'password'"
                @click:append="newPasswordForm.showRetypePassword = !newPasswordForm.showRetypePassword"
                @input="newPasswordForm.retypePasswordError = null"
                hide-details="auto"
                :error-messages="newPasswordForm.retypePasswordError"
                v-model="newPasswordForm.retypePassword"
              >
                <template #label>
                  Retype New Password<span class="red--text"
                    ><strong> * </strong></span
                  >
                </template>
              </v-text-field>
              <v-btn
                type="submit"
                :disabled="!usernameForm.valid || loading"
                block
                @click="resetPassword"
                color="primary"
                >Reset Password</v-btn
              >
            </v-form>
          </div>
        </div>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>
<script>
function initialState() {
  return {
    visible: false,
    showSupportSheet: false,
    loading: false,
    userId: null,
    auth: null,
    captchaVerified: false,
    recaptchaVerifier: null,
    confirmationResult: null,
    usernameForm: { username: null, valid: false, error: null },
    usernameVerified: false,
    otpVerified: false,
    enableResendButton: false,
    codeForm: {
      code: null,
      valid: false,
    },
    newPasswordForm: {
      newPasswordRules: [
        (v) => !!v || "Required",
        (v) =>
          !v ||
          v.length == 0 ||
          v.length >= 8 ||
          "Must be more than 8 characters",
      ],

      newPassword: null,
      showNewPassword: false,
      retypePassword: null,
      showRetypePassword: false,
      passwordError: null,
      retypePasswordError: null,
    },
  };
}

import Mixins from "@utils/mixins";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";

export default {
  name: "ForgotPassword",

  mixins: [Mixins.essentials],
  data() {
    return initialState();
  },
  methods: {
    async phoneCall(number) {
      window.open("tel:" + number);
    },
    async showCaptcha() {
      setTimeout(() => {
        this.auth = getAuth();

        this.recaptchaVerifier = new RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "normal",
            callback: (response) => {
              console.log(response);
              this.captchaVerified = true;

              // reCAPTCHA solved, allow signInWithPhoneNumber.
              // ...
            },
            "expired-callback": () => {
              this.showSnackbar({
                text: "Verification failed. We request you to refresh the page and try again!",
                type: "error",
              });
              // Response expired. Ask user to solve reCAPTCHA again.
              // ...
            },
          },
          this.auth
        );
        this.recaptchaVerifier.render();
      }, 500);
    },

    async getPhoneNumber() {
      this.loading = true;
      var url = this.Helper.addUrlParams(this.endpoints.getPhoneFromUsername, [
        "username=" + this.usernameForm.username,
        "code=" + this.$route.params.code,
      ]);
      var response = await this.api.call(this.essentials, url);
      if (response) {
        this.loading = false;
        this.userId = response.id;
        return response.phone;
      }
      this.loading = false;

      this.usernameForm.error = "User not found!";
      return null;
    },

    validPhoneFormat(phone) {
      if (!phone.length == 10) {
        this.showSnackbar({
          text: "Something wrong with your account's phone number. Please contact your institution",
          type: "error",
        });
        this.closeDialog();
        this.captchaVerified = false;
        return false;
      }
      return true;
    },

    async sendOTP() {
      var self = this;
      this.usernameForm.error = null;
      var phone = await this.getPhoneNumber();
      if (!phone || !this.validPhoneFormat(phone)) return;
      this.loading = true;
      phone = "+91" + phone;
      signInWithPhoneNumber(this.auth, phone, this.recaptchaVerifier)
        .then((confirmationResult) => {
          this.confirmationResult = confirmationResult;
          this.usernameVerified = true;
          this.loading = false;
          setTimeout(() => (self.enableResendButton = true), 10000);
        })
        .catch((e) => {
          this.loading = false;
          this.showSnackbar({
            text: "Something went wrong! Please try again.",
            type: "error",
          });
          this.captchaVerified = false;
          this.closeDialog();
          console.log(e);
        });
    },

    async verifyCode() {
      this.loading = true;
      this.confirmationResult
        .confirm(this.codeForm.code)
        .then(() => {
          this.loading = false;
          this.otpVerified = true;
        })
        .catch(() => {
          this.loading = false;

          this.codeForm.error = "Invalid OTP";
        });
    },

    async resetPassword() {
      if (
        this.newPasswordForm.newPassword != this.newPasswordForm.retypePassword
      ) {
        this.loading = false;

        this.showSnackbar({ text: "Passwords do not match!", type: "error" });
        return;
      }
      this.loading = true;

      var response = await this.api.call(
        this.essentials,
        this.endpoints.resetPassword,
        this.api.Methods.POST,
        {
          username: this.usernameForm.username,
          user_id: this.userId,
          password: this.newPasswordForm.newPassword,
        }
      );
      this.loading = false;
      if (response) {
        this.showSnackbar({
          title: "Password reset successfully!",
          type: "success",
        });
        this.closeDialog();
      }
    },
    async closeDialog() {
      this.visible = false;
      Object.assign(this.$data, initialState());
    },
  },
};
</script>
<style scoped>
#recaptcha-container {
  display: inline-block;
}
</style>