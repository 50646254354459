<template>
  <!-- <LoginInstituteIcon /> -->
  <div class="login-user-page">
    <v-app>
      <v-container
        class="login-user-container"
        :style="
          institution
            ? {
                'background-image': 'url(' + institution.image + ')',
              }
            : {}
        "
        fill-height
        fluid
      >
        <v-row justify="center" align="center">
          <v-col cols="12" md="5" align-self="center">
            <v-scroll-y-transition>
              <v-card
                v-if="institution"
                transition="fade-transition"
                class="login-user-card text-center px-2 py-4 mt-4"
              >
                <div
                  class="rounded-image mb-4"
                  v-if="institution"
                  :style="{
                    'background-image':
                      'url(' + institution.logo || '' + ') !important',
                    'background-size': 'cover',
                    'background-position': 'center',
                    'background-size': 'cover',
                  }"
                ></div>
                <h3>{{ institution.name }}</h3>
                <p>{{ institution.location }}</p>
                <v-form class="px-2" @submit.prevent="login">
                  <p
                    v-if="formErrors.genError"
                    class="form-error"
                    data-cy="login-user-form-errors"
                  >
                    {{ formErrors.genError }}
                  </p>
                  <v-text-field
                    v-model="username"
                    class="mb-4 username-field"
                    solo
                    clearable
                    label="Username"
                    rounded
                    @input="resetErrors"
                    :error-messages="formErrors.usernameError"
                    hide-details="auto"
                  >
                  </v-text-field>
                  <v-text-field
                    v-model="password"
                    class="mb-4"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                    solo
                    @input="resetErrors"
                    label="Password"
                    rounded
                    :error-messages="formErrors.passwordError"
                    hide-details="auto"
                  >
                  </v-text-field>
                  <v-btn
                    :disabled="isLoading"
                    large
                    color="primary"
                    block
                    type="submit"
                    >Log In</v-btn
                  >
                  <!-- <p class="mt-3 my-0 text-decoration-underline body-2">
                    <a to="/users">Forgot Password?</a>
                  </p> -->
                  <forgot-password-sheet />
                </v-form>
              </v-card>
            </v-scroll-y-transition>
          </v-col>
        </v-row>
      </v-container>
    </v-app>
  </div>
</template>
<script>
import Helper from "@utils/misc";
import { mapActions, mapGetters } from "vuex";
import endpoints from "@api/repository";
import api from "@api/index";
import authApi from "@api/auth/base";
import ForgotPasswordSheet from "@components/auth/ForgotPasswordSheet";
export default {
  name: "Login",
  components: {
    ForgotPasswordSheet,
  },
  data() {
    return {
      Helper: Helper,
      username: "",
      password: "",
      resetPassword: {
        showDialog: false,
        resetPasswordForm: true,
        passwordResetUsername: null,
        showEmailSentDialog: false,
        userEmail: null,
      },
      showPassword: false,
      formErrors: {
        genError: null,
        usernameError: null,
        passwordError: null,
        resetPasswordUsernameError: null,
      },
      institution: null,
      fee_sessions: [],
    };
  },

  computed: {
    ...mapGetters([
      "accessToken",
      "currentUser",
      "currentInstitution",
      "isLoading",
    ]),
    essentials() {
      return {
        accessToken: this.accessToken,
        handleErrorsFunction: this.handleApiError,
        setLoading: this.setLoading,
      };
    },
  },
  methods: {
    ...mapActions([
      "setLoading",
      "setTokens",
      "setCurrentUser",
      "setCurrentInstitution",
      "showSnackbar",
      "setAllRooms",
      "setUserSubjects",
    ]),

    async resetErrors() {
      this.formErrors.genError =
        this.formErrors.usernameError =
        this.formErrors.passwordError =
          null;
    },

    // get institution data [images]
    async getInstitutionDetails() {
      var url =
        endpoints.getInstitutionDetailsByCode + this.$route.params.code + "/";
      var response = await api.call(this.essentials, url);
      if (response) {
        this.institution = response;
        if(this.institution.preferences.theme_primary) this.$vuetify.theme.themes.light.primary = this.institution.preferences.theme_primary;
      }
    },
    // checks for any invalid or empty field
    validateFields() {
      Object.keys(this.formErrors).forEach((v) => (this.formErrors[v] = null));
      if (!this.username) {
        this.formErrors.usernameError = "This field is required";
        return false;
      }

      if (!this.password) {
        this.formErrors.passwordError = "This field is required";
        return false;
      }
      return true;
    },

    async login() {
      if (!this.validateFields()) return;
      this.setLoading(true);
      //creates token from phone and password
      let tokens = await authApi.createToken({
        username: this.username,
        password: this.password,
      });

      // handle token errors
      if (!Helper.handleErrors(tokens, this.handleApiError)) return;

      // set tokens in state
      await this.setTokens(tokens);

      // get currentUser
      let currentUser = await authApi.getMyDetails(tokens.access);

      // handle currentUser errors
      if (currentUser instanceof Error) {
        this.setLoading(false);
        this.snackbar.text = "There was a server error. Please, try again!";
        this.snackbar.visible = true;
        return;
      }

      if (currentUser.institution.id != this.institution.id) {
        this.setLoading(false);
        this.formErrors.genError =
          "The user account does not belong to this institution.";
        this.clearTokens();
        return;
      }
      // else if (!currentUser.is_admin) {
      // this.setLoading(false);
      // this.snackbar.text = "Insufficient Privileges";
      // this.snackbar.visible = true;
      // return;
      // }

      //set user in state
      await this.setCurrentUser(currentUser);

      //set institution in state
      await this.setCurrentInstitution(this.institution);

      this.getAllRooms();
      this.getAllUserSubjects();
      this.setLoading(false);

      if (this.currentUser !== null && this.$route.query.next != null) {
        this.$router.replace(this.$route.query.next);
      } else {
        this.showSnackbar({
          title: "Welcome",
          text: "Successfully logged in!",
          type: "success",
        });
        this.$router.replace({ name: "Home" });
      }
    },

    async getAllRooms() {
      // get all rooms and store
      var allRoomsResponse = await api.call(
        this.essentials,
        endpoints.getAllRooms
      );
      if (allRoomsResponse) {
        this.setAllRooms(allRoomsResponse.filter(r => !r.hide_from_app));
      }
    },

    async getAllUserSubjects() {
      var url = Helper.addUrlParams(
        endpoints.subjectViewSet,
        this.currentUser.is_student
          ? "room=" + this.currentUser.room.id
          : "faculty=" + this.currentUser.id
      );

      var subjects = await api.call(this.essentials, url);
      this.setUserSubjects(subjects);
    },

    handleApiError(err) {
      console.log(err);
      console.log("handling errors");
      this.setLoading(false);
      if (err.response) {
        if (err.response.status == 401) {
          this.formErrors.genError = "Incorrect username or password";
        }
        if (err.response.status == 412) {
          this.formErrors.resetPasswordUsernameError =
            "Account does not have Email linked. Please contact administrator.";
        }
      } else {
        this.snackbar.text = "Unable to process request";
        this.snackbar.visible = true;
      }
    },
  },
  created() {
    this.getInstitutionDetails();
  },
};
</script>
<style scoped>
.rounded-image {
  display: flex;
  margin: 0 auto;
  height: 100px;
  width: 100px;
  border-radius: 100% !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  justify-content: center;
  align-items: center;
}

.login-user-container {
  z-index: 10;
  position: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0px 0px 0px 0px;
}

::-webkit-input-placeholder {
  text-transform: initial;
}

:-moz-placeholder {
  text-transform: initial;
}

::-moz-placeholder {
  text-transform: initial;
}

:-ms-input-placeholder {
  text-transform: initial;
}
</style>

